<template>
<b-overlay
      :show="loading"
      spinner-variant="primary"
      spinner-type="grow"
      spinner-small
      rounded="sm"
    >
  <b-card title="Beta testeurs">
    
    <!-- search input -->
    <br /><br />
    <!-- table -->
    <vue-good-table
      :columns="columns"
      :rows="betas"
      :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >
        <span
          v-if="props.column.field === 'name'"
          class="text-nowrap"
        >
          <span class="text-nowrap">{{ props.row.name }}</span>
        </span>

        <span
          v-else-if="props.column.field === 'color'"
          class="text-nowrap"
        >
          <span class="text-nowrap">{{ props.row.color.name }}</span>
        </span>
       
        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'price'">
          {{ props.formattedRow[props.column.field] }}€
        </span>
        <span v-else-if="props.column.field === 'action'">
          <span>
            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>
              <b-dropdown-item @click="$router.push({name:'detailBeta', params: { id: props.row.id }})">
                <feather-icon
                  icon="ZoomInIcon"
                  class="mr-50"
                />
                <span>Voir détail</span>
              </b-dropdown-item>
              <b-dropdown-item  @click="acceptBeta(props.row.id)">
                <feather-icon
                  icon="CheckIcon"
                  class="mr-50"
                />
                <span>Valider</span>
              </b-dropdown-item>
            </b-dropdown>
          </span>
        </span>
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap ">
              Page 1 de
            </span>
            <b-form-select
              v-model="pageLength"
              :options="['3','5','10','50']"
              class="mx-1"
              @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap"> sur {{ props.total }} résultats </span>
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="totalRows"
              v-model="currentPage"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      
      </template>
    </vue-good-table>

  </b-card>
</b-overlay>
</template>

<script>
import {BModal, BButton, VBModal, BCard, BOverlay, BCardText, BAvatar, BBadge, BPagination, BFormGroup, BFormInput, BFormSelect, BDropdown, BDropdownItem, } from 'bootstrap-vue'
import {APIRequest} from '@simb2s/senseye-sdk'
import { VueGoodTable } from 'vue-good-table'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCard,
    BCardText,
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BOverlay,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BModal,
    BButton
  },
  data() {
    return {
      betas:[],
      pageLength: 50,
      totalRows:0,
      currentPage:1,
      loading:true,
      dir: false,
      selectedItem:null,
      columns: [
        {
          label: 'Nom',
          field: 'name',
        },
        {
          label: 'Email',
          field: 'email',
        },
        {
          label: 'Status',
          field: 'status',
        },
        {
          label: 'Action',
          field: 'action',
        }
        
      ],
      rows: [],
      searchTerm: '',

    }
  },
  watch:{
    currentPage:async function(){
      this.loading=true
      let offset=this.currentPage*this.pageLength
      let betas=await APIRequest.list('beta-testers', null, null, this.pageLength,offset)
      this.betas=betas[0]
      this.loading=false
    }
  },
  methods:{
   async acceptBeta(id){
    this.loading=true
    let parameters={status:'ok'}
    await APIRequest.update('beta-testers', id, parameters)
    this.$toast({
      component: ToastificationContent,
      props: {
        title: 'Le beta testeur a bien été accepté !',
        icon: 'CheckIcon',
        variant: 'success',
      },
    })
    let betas=await APIRequest.list('beta-testers')
    this.betas=betas[0]
    this.totalRows=betas[1]
    this.loading=false
   }
  },
  async mounted()
  {
    let betas=await APIRequest.list('beta-testers')
    this.betas=betas[0]
    this.totalRows=betas[1]
    this.loading=false
  }
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
